/* eslint-disable no-unused-vars */
import { use, useEffect, useMemo, useState } from 'react'
import cn from 'classnames'
import s from './Footer.module.css'
import resetStyle from './reset.module.css'
import Icon from '@components/icons'
import { Picture, Link } from '@components/ui'
import referralApi from 'templates/Pages/Brands/Referral/api'
import Cookies from 'js-cookie'
import { useProfile } from '@components/common/ProfileContext'
import registryStyle from '@components/common/Registrations/index.module.css'
import RegistrationsComponent from '@components/common/Registrations/components/RegistrationsComponent'
import { RegistrationsStatus } from '@components/common/Registrations/const'
import registrationsTrack from '@components/common/Registrations/RegistrationsTrack'
import { motion } from 'framer-motion'
import { MULPASS_URL, SHORT_LOCALE_MAP, ORIGIN_URL } from '@shopify/const'
import { getAppName } from '@shopify/utils'
import { handleMenuUrl, tsHtml2LocaleLink } from '@lib/utils/tools'
import { features } from 'commerce.config'
import AppLoading from '../Modal/AppLoading'
import { useModal } from '@ebay/nice-modal-react'

const slideVariants = {
  hidden: { height: 0 },
  visible: { height: 'auto' },
}

/**
 * Footer component.
 *
 * @param {Object} props - The component props.
 * @param {string} props.className - The class name for the component.
 * @param {Object} props.metafields - The metafields object.
 * @param {string} props.locale - The locale for the component.
 * @returns {JSX.Element} The rendered Footer component.
 */
const Footer = ({ className, metafields, locale, handle }) => {
  const {
    footerSetting = {},
    footerMenu = {},
    footerCountry = {},
    registrationsSettings,
    hideFooterRegister,
    globalCountry,
    shopCommon,
  } = metafields

  const [isProductPage, setIsProductPage] = useState(false)
  const appLoading = useModal(AppLoading)

  const registrationsMain = {
    ...registrationsSettings?.registrationsMain,
    ...footerSetting?.registrations,
  }

  const [currentStatus, setCurrentStatus] = useState('main')
  const { profile } = useProfile()
  const gs = useMemo(() => {
    return currentStatus === 'main'
      ? { ...registryStyle, ...s }
      : { ...registryStyle, ...resetStyle }
  }, [currentStatus])

  const onClose = (status) => {
    switch (status) {
      case RegistrationsStatus.QUICK_LOGIN:
        registrationsTrack.closeTrack({
          position: '',
          page_group: 'login_pop',
          button_name: 'close',
        })
        break
      case RegistrationsStatus.REMAINDER:
        registrationsTrack.closeTrack({
          position: '',
          page_group: 'reg_sub_pop',
          button_name: 'close',
        })
        break
      /*  case RegistrationsStatus.MAIN:
        registrationsTrack.closeTrack({
          position: '',
          page_group: 'reg_sub_pop',
          button_name: 'close',
        })
        break */
      case RegistrationsStatus.PASSWORD_LOGIN:
        registrationsTrack.closeTrack({
          position: '',
          page_group: 'login_pop',
          button_name: 'close',
        })
        break
      case RegistrationsStatus.COUPONS:
        registrationsTrack.closeTrack({
          position: 'success',
          page_group: 'reg_sub_pop',
          button_name: 'close',
        })
        const hostUrl = window.location.host.split('.')
        const domain = window.location.host.includes('localhost')
          ? 'localhost'
          : `.${hostUrl[1]}.${hostUrl[2]}`
        const passportLoginUrl = Cookies.get('passport-login-url')
        passportLoginUrl &&
          Cookies.remove('passport-login-url', {
            domain,
            expires: 30,
          })
        if (passportLoginUrl) {
          appLoading.show()
          window.location.href = passportLoginUrl
        }
        break
      default:
        break
    }
    setCurrentStatus('main')
  }

  useEffect(() => {
    if (currentStatus !== 'main') {
      document.querySelector('html').classList.add('fw-hidden')
    } else {
      document.querySelector('html').classList.remove('fw-hidden')
    }
  }, [currentStatus])

  useEffect(() => {
    setIsProductPage(window.location.pathname.includes('/products'))
  }, [])

  const {
    advantage,
    contact,
    socials,
    appDownload,
    paymentList,
    brands,
    hideBrandList,
    copyright,
  } = footerSetting || {}

  const [isCountry, setIsCountry] = useState(false)

  const [current, setCurrent] = useState(-1)

  const handleTab = (index) => {
    setCurrent((prevIndex) => (prevIndex === index ? -1 : index))
  }

  // 解析环境变量
  const ThemeCountryMap = SHORT_LOCALE_MAP || {}

  // 设置cookie的过期时间
  const COOKIE_EXPIRES = 365

  const countrySwitch = (shop) => {
    // 获取locale，如果ThemeCountryMap中没有对应的值，就使用shop
    let locale = ThemeCountryMap[shop] || shop

    // 获取域名
    const hostUrl = window.location.host.split('.')
    const domain =
      hostUrl[0].indexOf('beta-') > -1
        ? window.location.host
        : `.${hostUrl[1]}.${hostUrl[2]}`

    // 设置cookie
    Cookies.set('country_switch', locale, {
      domain,
      expires: COOKIE_EXPIRES,
    })
  }

  const showContact = (item) => {
    // 如果图标是 'contact-chat'，则尝试打开聊天窗口
    if (item.icon === 'contact-chat') {
      // 获取自定义元素并访问其 shadowRoot
      const myElement = document.querySelector('shulex-chatbot-lancher')
      const shadowRoot = myElement?.shadowRoot

      if (shadowRoot) {
        // 在 shadowRoot 中查找聊天窗口和帮助按钮的选择器
        const openedChatWindow = shadowRoot.querySelector(
          '.hNHpcMLA1GTj5aTUxA4_B6BDvU_An51tKSj6qa8F'
        )
        const helpButton = shadowRoot.querySelector('.hNHpcMLA1GTj5aTUxA4_')

        // 如果聊天窗口已打开，则点击它，否则点击帮助按钮
        if (openedChatWindow) {
          openedChatWindow.click()
        } else if (helpButton) {
          helpButton?.click()
        }
      } else {
        // 如果没有 shadowRoot，使用全局选择器查找聊天窗口和帮助按钮
        const SIDEBAR_SELECTOR = '.embeddedServiceSidebarMinimizedDefaultUI'
        const HELP_BUTTON_SELECTOR = '.helpButtonEnabled'

        // 尝试获取已打开的聊天窗口
        const openedChatWindow = document.querySelector(SIDEBAR_SELECTOR)

        if (openedChatWindow) {
          // 如果聊天窗口已打开，点击它
          openedChatWindow.click()
        } else {
          // 否则，点击帮助按钮以打开聊天窗口
          const helpButtonElement = document.querySelector(HELP_BUTTON_SELECTOR)
          helpButtonElement?.click()
        }
      }
    }
  }

  // useEffect(() => {
  //   referralApi.uploadCommerceEvents(
  //     {
  //       menu: 'Navigation',
  //       sub: 'referral-new',
  //       type: 'page',
  //       name: 'Bottom',
  //     },
  //     locale
  //   )
  // }, [locale])

  const SOCIAL_SHARE = () => {
    return (
      <ul className={s.social}>
        {socials.map((item, i) => (
          <li key={`MENU_LIST_SOCIAL${i}`} tag={'Auto-0000025' + (i + 1)}>
            <Link
              href={item.link}
              target={item.target || '_blank'}
              rel="noopener noreferrer"
            >
              <Icon
                aria-label={item.icon}
                iconKey={item.icon}
                className={s.icon}
              />
            </Link>
          </li>
        ))}
      </ul>
    )
  }

  const COUNTRY_CHOOSE = () => {
    const categorizedCountry = useMemo(() => {
      const res = {}
      Object.entries(features).forEach(([key, value]) => {
        const feature = { ...value, locale: key }
        const { continent } = feature
        if (!res[continent]) {
          res[continent] = []
        }
        res[continent].push(feature)
      })
      return res
    }, [features])

    const [expandedItems, setExpandedItems] = useState(
      Array.from({ length: Object.keys(categorizedCountry).length }, () => ({
        expand: true,
      }))
    )

    const CountryItem = ({ item, index }) => {
      const changeCountry = () => {
        const localeswitcher_off = JSON.parse(
          localStorage.getItem('localeswitcher_off') || '{}'
        )
        localeswitcher_off[item.locale] = true
        localStorage.setItem(
          'localeswitcher_off',
          JSON.stringify(localeswitcher_off)
        )
      }
      return (
        <li
          className={s['country-list-item']}
          key={index}
          tag={'Auto-0000026' + (index + 1)}
        >
          <a
            className="flex flex-col min-md:flex-row min-md:justify-between"
            href={handleMenuUrl({
              locale,
              href: `${ORIGIN_URL}/${item?.locale}`,
              label: 'ref',
              val: `country_switch_manual_from_${locale}`,
            })}
            onClick={changeCountry}
          >
            <span className={s['country-txt']}>{item.countryName}</span>

            <span className={s['country-lang']}>{item.lang}</span>
          </a>
        </li>
      )
    }

    return (
      <div className={s['country-switch']}>
        <div
          key={`MENU_LIST_COUNTRY_CURRENT_${features?.[locale]?.countryName}`}
          className="flex items-center"
          onClick={() => setIsCountry(true)}
          tag="Auto-0000026"
        >
          {/* <Picture
                  aria-label={item.shop}
                  alt={item.shop}
                  className={s.current_country}
                  size={{ width: 24, height: 18 }}
                  source={`${item.img}`}
                  onClick={() => setIsCountry(true)}
                /> */}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            className={s.current_country}
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12.4493 3.51194C7.51554 3.51194 3.51595 7.50098 3.51595 12.4217C3.51595 17.3424 7.51554 21.3315 12.4493 21.3315C17.383 21.3315 21.3826 17.3424 21.3826 12.4217C21.3826 7.50098 17.383 3.51194 12.4493 3.51194ZM2.18262 12.4217C2.18262 6.76654 6.77916 2.18213 12.4493 2.18213C18.1194 2.18213 22.716 6.76654 22.716 12.4217C22.716 18.0769 18.1194 22.6613 12.4493 22.6613C6.77916 22.6613 2.18262 18.0769 2.18262 12.4217Z"
              fill="currentColor"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M21.3119 9.47679H3.58887V8.14502H21.3119V9.47679Z"
              fill="currentColor"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M20.9222 16.8445H3.58887V15.5127H20.9222V16.8445Z"
              fill="currentColor"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M8.04333 12.1369C8.09123 8.40998 9.43109 4.71996 11.9962 2.3584L12.9006 3.33555C10.6657 5.39307 9.42095 8.69993 9.37655 12.154C9.33222 15.6026 10.4866 19.0994 12.9198 21.5262L11.977 22.4665C9.24098 19.7377 7.99535 15.8693 8.04333 12.1369Z"
              fill="currentColor"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M16.8541 12.7063C16.8062 16.4333 15.4664 20.1233 12.9012 22.4849L11.9969 21.5077C14.2317 19.4502 15.4765 16.1433 15.5209 12.6893C15.5652 9.24066 14.4109 5.7439 11.9777 3.31711L12.9205 2.37678C15.6565 5.10558 16.9021 8.97395 16.8541 12.7063Z"
              fill="currentColor"
            />
          </svg>
          <span className={s.txt}>{features?.[locale]?.countryName}</span>
        </div>
        <div
          className={cn(s['country-select-pop-wrap'], {
            '!block': isCountry,
          })}
        >
          <i className={s['mask']}></i>
          <div className={s['country-select-pop']}>
            <div className={s['country-select-pop-inner']}>
              <div
                className={cn(
                  s['pop-padding'],
                  s['country-pop-head'],
                  'flex items-center justify-between border-b-[1px] border-b-[#E2E2E2]'
                )}
              >
                <p
                  className={s['country-pop-title']}
                  dangerouslySetInnerHTML={{
                    __html:
                      shopCommon?.country_pop_title ||
                      'Select Your Country/Region',
                  }}
                ></p>
                <span
                  className={s['country-pop-close']}
                  onClick={() => setIsCountry(false)}
                >
                  <svg
                    width="22"
                    height="22"
                    viewBox="0 0 22 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M16.8525 5.31015C16.4558 4.91337 15.8125 4.91338 15.4157 5.31015L11.1268 9.5991L6.83815 5.31049C6.44138 4.91372 5.79808 4.91372 5.40131 5.31049C5.00454 5.70726 5.00455 6.35055 5.40131 6.74732L9.68993 11.0359L5.40105 15.3248C5.00428 15.7216 5.00428 16.3649 5.40105 16.7616C5.79782 17.1584 6.44111 17.1584 6.83788 16.7616L11.1268 12.4728L15.416 16.762C15.8128 17.1588 16.456 17.1588 16.8528 16.762C17.2496 16.3652 17.2496 15.7219 16.8528 15.3252L12.5636 11.0359L16.8525 6.74698C17.2493 6.35021 17.2493 5.70692 16.8525 5.31015ZM16.921 6.02856C16.921 6.22992 16.8441 6.43127 16.6905 6.5849L14.465 8.8104L12.2395 11.0359L12.2395 11.0359L16.6905 6.58493C16.8441 6.43129 16.921 6.22993 16.921 6.02856ZM5.34966 6.19087C5.37992 6.3354 5.45115 6.47307 5.56336 6.58528L10.014 11.0359L10.014 11.0359L5.56336 6.58524C5.45116 6.47304 5.37992 6.33539 5.34966 6.19087ZM5.34682 16.1923C5.37547 16.3415 5.44757 16.484 5.56309 16.5996C5.87037 16.9068 6.36856 16.9068 6.67583 16.5996L11.1268 12.1486L15.578 16.5999C15.8853 16.9072 16.3835 16.9072 16.6908 16.5999C16.8462 16.4445 16.923 16.2402 16.9212 16.0365C16.923 16.2402 16.8462 16.4445 16.6908 16.5999C16.3835 16.9072 15.8853 16.9072 15.578 16.5999L11.1268 12.1487L6.67583 16.5996C6.36856 16.9069 5.87037 16.9069 5.56309 16.5996C5.44756 16.4841 5.37547 16.3415 5.34682 16.1923Z"
                      fill="#333333"
                    ></path>
                  </svg>
                </span>
              </div>

              <div className="h-[calc(100%-73px)] overflow-y-auto">
                <div
                  className={cn(
                    'px-6 pb-[24px]',
                    s['header-border-line'],
                    s['country-pop-body']
                  )}
                >
                  {Object.entries(categorizedCountry)?.map(
                    ([countyName, countries], index) => {
                      return (
                        <div key={countyName + index}>
                          <div className="flex items-center justify-between pb-6 pt-8">
                            <div className="text-left text-[16px] font-semibold leading-[1.2] text-[#333] min-md:text-[18px]">
                              {countyName}
                            </div>
                            <div
                              onClick={() =>
                                setExpandedItems((prev) => {
                                  return prev.map((item, idx) => {
                                    if (idx === index) {
                                      item.expand = !item?.expand
                                    }
                                    return item
                                  })
                                })
                              }
                            >
                              {!expandedItems?.[index].expand ? (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  viewBox="0 0 20 20"
                                  fill="none"
                                >
                                  <path
                                    d="M4.21143 9.72534H16.2114"
                                    stroke="black"
                                    strokeLinecap="round"
                                  />
                                  <path
                                    d="M10.2119 3.72534L10.2119 15.7253"
                                    stroke="black"
                                    strokeLinecap="round"
                                  />
                                </svg>
                              ) : (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  viewBox="0 0 20 20"
                                  fill="none"
                                >
                                  <path
                                    d="M4.21143 9.72534H16.2114"
                                    stroke="black"
                                    strokeLinecap="round"
                                  />
                                </svg>
                              )}
                            </div>
                          </div>
                          <ul
                            className={cn(
                              s['country-pop-list'],
                              '!grid grid-cols-12'
                            )}
                          >
                            {expandedItems?.[index].expand &&
                              countries?.map((item, index) => {
                                return (
                                  <CountryItem
                                    key={item?.countryName}
                                    item={item}
                                    index={index}
                                  />
                                )
                              })}
                          </ul>
                        </div>
                      )
                    }
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className={cn(s.coutriesSwitch, { hidden: !isCountry })}>
          <ul>
            {footerCountry.list.map((item, index) => (
              <li key={`MENU_LIST_COUNTRY${index}`}>
                <a
                  href={item.link}
                  onClick={() => {
                    countrySwitch(item.shop)
                  }}
                >
                  <span>{item.txt}</span>
                </a>
              </li>
            ))}
          </ul>
        </div>
        <div
          className={cn(s.mask, { hidden: !isCountry })}
          onClick={() => setIsCountry(false)}
        ></div> */}
      </div>
    )
  }

  const APP_DOWNLOAD = () => {
    return (
      <div className={s['app-download']}>
        <div className={s['download-title']}>
          {/* <div className={s['divide-line']}></div> */}
          <p>{appDownload.title}</p>
          {/* <div className={s['divide-line']}></div> */}
        </div>
        <div className={cn(s['download-types'], s['app-wrappers'])}>
          {appDownload.appTypeList &&
            appDownload.appTypeList.map((item, index) => (
              <div
                className={cn(s['app-wrapper'], s['appliances-wrapper'])}
                key={`APP_DOWNLOAD${index}`}
                tag={'Auto-0000024' + (index + 1)}
              >
                <figure className={s['img-icon']}>
                  <Picture
                    aria-label={item.title}
                    alt={item.title}
                    size={{ width: 36, height: 36 }}
                    source={item.icon}
                  />
                </figure>
                <p className="text-center">{item.title}</p>
                <div className={s['app-box']}>
                  <ul className={s['appliances-content']}>
                    {item.apps.map((child, i) => (
                      <li
                        key={`APP_DOWNLOAD_LINK${i}`}
                        tag={'Auto-0000024' + (index + 1) + (i + 1)}
                      >
                        <Link
                          href={child.app_link}
                          params={{
                            label: 'ref',
                            val: 'footer',
                          }}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <figure className={s['app-img']}>
                            <Picture
                              size={{ width: 130, height: 36 }}
                              source={child.app_img}
                            />
                          </figure>
                        </Link>
                      </li>
                    ))}
                    <div className={s.poper__arrow}></div>
                  </ul>
                </div>
              </div>
            ))}
        </div>
      </div>
    )
  }

  const PAYMENT_LIST = () => {
    return (
      <div className={s['payment-list']}>
        {paymentList.map((item, index) => (
          <Icon key={`PAYMENT_LIST${index}`} iconKey={item}></Icon>
        ))}
      </div>
    )
  }

  const MENU_LIST = (item, index, locale) => {
    const handleReferralTrack = async (e) => {
      if (e?.target?.href?.includes('referral-new')) {
        e.preventDefault()
        try {
          await referralApi.uploadCommerceEvents(
            {
              menu: 'Navigation',
              sub: 'referral-new',
              type: 'click',
              name: 'Bottom',
            },
            locale
          )
          location.href = e?.target?.href
        } catch (error) {
          console.log(error)
        }
      }
    }
    return (
      <li
        key={`MENU_LIST${index}`}
        className={cn(
          'min-l:box-border min-l:w-[20%] min-l:pr-10 min-xl:mb-[26px]',
          {
            [s.active]: index === current,
          }
        )}
      >
        <div className={s['footer-title']} onClick={() => handleTab(index)}>
          {item.title}
          <i className={s.fold}></i>
        </div>
        <motion.div
          className="h-0 overflow-hidden min-l:!h-auto"
          variants={slideVariants}
          animate={index === current ? 'visible' : 'hidden'}
        >
          <ul className={cn({ [s.show]: index === current })}>
            {item.links.map((child, i) => (
              <li
                key={`MENU_LIST_CHILD${i}`}
                className={cn(child['hideIn']?.includes(locale) && '!hidden')}
              >
                <Link
                  href={child.link}
                  target={child.openNew ? '_blank' : ''}
                  rel="noopener noreferrer"
                  params={{
                    label: 'ref',
                    val: 'footer',
                  }}
                  onClick={handleReferralTrack}
                >
                  {child.name}
                </Link>
              </li>
            ))}
          </ul>
        </motion.div>
        {/* {item.showSocialShare && socials && SOCIAL_SHARE()}
        {item.showCountrySwitch && footerCountry?.list && COUNTRY_CHOOSE()} */}
      </li>
    )
  }

  return (
    <footer
      className={cn(s['footer-root'], {
        ['pb-[84px]']: isProductPage,
      })}
    >
      {registrationsSettings &&
        footerSetting?.registrations &&
        !profile &&
        !hideFooterRegister && (
          <div className="mx-auto px-[44px] py-[72px] min-md:max-w-[655px] min-md:py-[80px] min-l:max-w-[834px] min-l:px-8 min-xl:max-w-[1024px] min-xl:py-[122px] min-xl:pt-[60px] min-xxl:py-[152px] min-xxl:pt-[92px]">
            <div className={cn(s[currentStatus])}>
              <RegistrationsComponent
                onClose={onClose}
                gs={gs}
                locale={locale}
                data={{
                  ...registrationsSettings,
                  globalCountry,
                  registrationsMain,
                }}
                currentStatus={currentStatus}
                setCurrentStatus={setCurrentStatus}
              />
            </div>
          </div>
        )}
      {/* <Divider></Divider> */}
      <div className={s['footer-container']}>
        <div className={s['footer-content']}>
          <div className={s['advantage-contact']}>
            {contact?.list && (
              <section className={s['footer-contact']}>
                <div className={s['footer-title']}>{contact?.title}</div>
                <ul>
                  {contact?.list.map((item, index) => (
                    <li
                      key={`CONTACT_LIST${index}`}
                      onClick={() => showContact(item)}
                      className={cn({
                        ['cursor-pointer']: item.icon === 'contact-chat',
                      })}
                    >
                      <Icon
                        aria-label={item.icon}
                        iconKey={item.icon}
                        className="mr-[12px] h-[24px] w-[24px]"
                      />
                      <p
                        dangerouslySetInnerHTML={{ __html: item.content }}
                        tag={index === 0 ? '' : 'Auto-0000022' + (index + 1)}
                      ></p>
                    </li>
                  ))}
                </ul>
              </section>
            )}
            {advantage?.list && (
              <section className={s['footer-advantage']}>
                <div className={s['footer-title']}>{advantage?.title}</div>
                <ul>
                  {advantage?.list &&
                    advantage?.list.map((item, index) => {
                      let matchs = item.content?.match(/href='([^']*)'/)
                      let content = item.content
                      if (matchs && matchs.length > 1) {
                        content = content.replace(
                          matchs[1],
                          handleMenuUrl({ locale, href: matchs[1] })
                        )
                      }
                      return (
                        <li key={index}>
                          <Icon
                            aria-label={item.icon}
                            iconKey={item.icon}
                            className="mr-2 h-[24px] w-[24px]"
                          />
                          <p
                            dangerouslySetInnerHTML={{
                              __html: tsHtml2LocaleLink({
                                html: content,
                                locale,
                              }),
                            }}
                            tag={
                              index === 0
                                ? 'Auto-0000021'
                                : 'Auto-0000021' + index
                            }
                          ></p>
                        </li>
                      )
                    })}
                </ul>
              </section>
            )}
            {appDownload && APP_DOWNLOAD()}
            <div className="ml-auto hidden min-l:block min-l:w-[148px] min-xl:w-[304px]">
              {socials && SOCIAL_SHARE()}
              {paymentList && PAYMENT_LIST()}
            </div>
          </div>
          <section className={s['footer-payment']}>
            {appDownload && APP_DOWNLOAD()}
            {paymentList && PAYMENT_LIST()}
          </section>
          <section className={s['footer-maps']}>
            <ul className={s['category']}>
              {footerMenu.list &&
                footerMenu.list.map((item, index) =>
                  MENU_LIST(item, index, locale)
                )}
            </ul>
            <div className={s['media-payment']}>
              <div className={s['media-box']}>
                {socials && (
                  <div className={s['media-share']}>
                    <SOCIAL_SHARE />
                  </div>
                )}
                <COUNTRY_CHOOSE />
              </div>
            </div>
          </section>
          <section className={s['footer-brands']}>
            {/* {appDownload && APP_DOWNLOAD(true)} */}

            <div className="min-l:flex min-l:justify-between min-l:border-b min-l:border-[#e9e9e9] min-l:pb-10">
              {!hideBrandList && (
                <ul className={s['brands-logo']}>
                  {brands &&
                    brands.map((item, index) => (
                      <li key={`BRAND_LOGO${index}`}>
                        <a
                          href={handleMenuUrl({
                            locale,
                            href: item.link,
                          })}
                          rel="noopener noreferrer"
                          target={item.target || '_blank'}
                        >
                          <Icon
                            aria-label={item.icon}
                            iconKey={item.icon}
                            className={cn(s.icon, s['icon-' + item.icon])}
                          ></Icon>
                        </a>
                      </li>
                    ))}
                </ul>
              )}
              <COUNTRY_CHOOSE />
            </div>
            <div className={s.copyright}>
              <p
                className="border-b border-[#e9e9e9] pb-3 min-md:border-0 min-l:hidden"
                dangerouslySetInnerHTML={{
                  __html:
                    copyright?.text ||
                    '© Fantasia Trading LLC 2022 200923810277',
                }}
              ></p>
              {copyright?.links && (
                <ul>
                  <li
                    className={s.cpright}
                    dangerouslySetInnerHTML={{
                      __html:
                        copyright?.text ||
                        '© Fantasia Trading LLC 2022 200923810277',
                    }}
                  ></li>
                  {copyright?.links.map((item, index) => (
                    <li key={index}>
                      <Link
                        href={item.link}
                        rel="noopener noreferrer"
                        dangerouslySetInnerHTML={{ __html: item.label }}
                        {...item.attr}
                      ></Link>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </section>
        </div>
      </div>
    </footer>
  )
}

export default Footer
